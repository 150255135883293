/* .view{
    width: 100vw;
    height: 100vh;
    background-color: rgb(236, 236, 236);
}
.loginCon{
    width: 400px;
    height: 60vh;
    background-color: rgb(255,255,255);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
}
.view .top{
    text-align: center;
    background-color: #1c7ed6;
    color: white;
    padding: 10px;
}
.top > h2{
    font-size: 38px;
    margin-bottom: 6px;
}
.top > h5{
    font-size: 25px;
}

.buttom{
    padding: 10%;
}
.getinput{
    display: flex;
    flex-direction: column;
}
.username{
    display: flex;
    flex-direction: column;
    font-weight: 550;
}
.username input{
    padding: 10px 5px;
    margin-top: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: #1c7ed6 1px solid;
    outline: none;
    margin: 15px 0;
}
.view .password{
    display: flex;
    flex-direction: column;
    font-weight: 550;
}
.view .password input{
    padding: 10px 5px;
    margin-top: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: #1c7ed6 1px solid;
    outline: none;
    margin: 8px 0;
}
.view button{
    background-color: #1c7ed6;
    width: 150px;
    display: block;
    margin: auto;
    padding: 11px 7px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    color: white;
    margin-top: 15px;
}
.view  p{
    padding: 20px;
    text-align: center;
    color: #1c7ed6;
} */


.BannerFrist {
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }
  .BannerFrist > .LoginCon > .Log .Getinput *{
    margin-top: 2px;
  }

 @media only screen and (min-width: 1000px) {
    .BannerFrist {
        display: flex;
        max-width: 90vw;
      }
      
      .BannerFrist > .LoginCon {
        width: 40%;
        position: relative;
      }
      .BannerFrist > .LoginCon > .Log{
        position: absolute;
        min-width: 400px;
        top: 0%;
        left: 0%;
        transform: translate(0%,40%); 
      }
      .BannerFrist > .Slider {
        width: 50%;
      }
  }