.userdata .datamain{
    display: flex;
    height: 90vh;

}
.userdata .datamain .userRight{
    background-color: #fff;
    margin:20px;
    flex: 30%;
    padding: 20px;
    border-radius: 20px;
}
.userdata .datamain .regInput *{
    padding: 10px;
    width: 100%;
    font-size: large;
    outline: none;
}
.userdata .datamain .userLeft{
    flex: 60%;
    overflow-x: scroll;
    width: 90vh;
    background-color: #fff;
    margin:20px;
    border-radius: 20px;
    padding: 10px;
}
.userdata .datamain .userLeft .userLeftdiv{
    display: grid;
}
.userdata .datamain .userLeft .userLeftdiv .userLeftsmalldiv{
    background-color: #e7e7e7;
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    display: flex;
    justify-content: space-between;
}
.userdata .datamain .userLeft .userLeftdiv .userLeftsmalldiv button{
    height: 100%;
    padding: 10px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    color: #fff;
}