.viewchange{
    width: 100vw;
    height: 100vh;
    background-color: rgb(236, 236, 236);
}
.viewConchange{
    width: 400px;
    height: 62vh;
    background-color: rgb(255,255,255);
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 10px;
}
.viewchange .topchange{
    text-align: center;
    background-color: #1c7ed6;
    color: white;
    padding: 10px;
}
.top > h2{
    font-size: 38px;
    margin-bottom: 6px;
}
.top > h5{
    font-size: 25px;
}

.buttomchange{
    padding: 10%;
}
.getinput{
    display: flex;
    flex-direction: column;
}
.usernamechange{
    display: flex;
    flex-direction: column;
    font-weight: 550;
}
.passwordchange{
    display: flex;
    flex-direction: column;
    font-weight: 550;
}
.passwordchange input{
    padding: 10px 5px;
    margin-top: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: #1c7ed6 1px solid;
    outline: none;
    margin: 15px 0;
}
.usernamechange input{
    padding: 10px 5px;
    margin-top: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: #1c7ed6 1px solid;
    outline: none;
    margin: 15px 0;
}
.viewchange .number{
    display: flex;
    flex-direction: column;
    font-weight: 550;
}
.viewchange .number input{
    padding: 10px 5px;
    font-size: 15px;
    border-radius: 5px;
    border: #1c7ed6 1px solid;
    outline: none;
    margin: 15px 0;
}
.viewchange > .password{
    display: flex;
    flex-direction: column;
    font-weight: 550;
}
.viewchange > .password input{
    padding: 10px 5px;
    margin-top: 5px;
    font-size: 15px;
    border-radius: 5px;
    border: #1c7ed6 1px solid;
    outline: none;
    margin: 8px 0;
}
.viewchange button{
    background-color: #1c7ed6;
    width: 150px;
    display: block;
    margin: auto;
    padding: 11px 7px;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 18px;
    color: white;
    margin-top: 15px;
}
.viewchange  p{
    padding: 20px;
    text-align: center;
    color: #1c7ed6;
}