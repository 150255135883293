.home{
    max-width: 100%;
    min-height: 100vh;
    background-color: rgb(232, 232, 232);
}
.homeCon{
    display: flex;
    margin: auto;
    width: 90%;
}
.homeCon > .left{
    flex: 0.7;
    border-radius: 10px;
}
.homeCon > .left .top{
    height: 55vh;
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px;
}
.homeCon > .left .bottom{
    height: 30vh;
    margin-top: 20px;
    border-radius: 10px;
    background-color: white;
}
.homeCon > .left  .inputIn{
    display: flex;
    margin: auto;
    margin-top: 10px;
}
.homeCon > .left  .inputIn .input_left{
    flex: 0.5;
    padding: 10px;
}
.homeCon > .left  .inputIn .input_right{
    flex: 0.5;
    padding: 10px;
}
.homeCon > .left  .inputIn .input_right div{
    display: flex;
    flex-direction: column;
}
.homeCon > .left  .inputIn .input_right div textarea{
    padding: 10px;
    border-radius: 5px;
    border: gray 1px solid;
    outline: none;
    margin: 5px 0;
}
.homeCon > .left  .inputIn .input_right div input{
    padding: 10px;
    border-radius: 5px;
    border: gray 1px solid;
    outline: none;
    margin: 5px 0;
}
.homeCon > .left  .inputIn .input_left div{
    display: flex;
    flex-direction: column;
}
.homeCon > .left  .inputIn .input_left div input{
    padding: 10px;
    border-radius: 5px;
    border: gray 1px solid;
    outline: none;
    margin: 5px 0;
}
.homeCon > .left button{
    margin-top: 5px;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    background-color: #099268;
    border-radius: 5px;
    border: none;
    color: white;
    width: 98%;
    margin: auto;
    display: block;
}
.homeCon > .left  button:hover{
    background-color: #087f5b;
}

.homeCon > .right{
    flex: 0.3;
    background-color: white;
    height: 87vh;
    margin: 20px;
    padding: 15px;
    border-radius: 10px;
    overflow-y: auto;
}
.homeCon > .right >h3{
    margin-bottom: 5px;
}
.homeCon > .right .detail{
    background-color: rgb(242, 242, 242);
    padding: 8px;
    line-height: 21px;
    border-radius: 5px;
    margin-bottom: 5px;
}

.homeCon > .right .detail .debtn{
    background: rgb(214, 0, 0);
    color: aliceblue;
    font-weight: 600;
    text-decoration: none;
    padding: 8px;
    border: none;
    border-radius: 5px;
    margin: 5px 5px 5px 0;
    font-weight: 600;
}
.homeCon > .right .detail .edbtn{
    background: #099268;
    color: aliceblue;
    font-weight: 600;
    text-decoration: none;
    padding: 8px;
    border: none;
    border-radius: 5px;
    margin: 5px 5px 5px 0;
    font-weight: 600;
}

.homeCon > .right .detail a{
    color: black;
    text-decoration: none;
    font-weight: 800;
}
.homeCon > .right .detail a:hover{
    color: #0076de;
    
}
#searchData{
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    outline: none;
    box-shadow: none;
    border: 1px solid gray;
    margin: 10px auto;
}