.cards{
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin: 20px auto;
    flex-direction: column;
}
@media only screen and (min-width:800px) {
    .cards{
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 20px auto;
        flex-direction: row;
    }
}