*{
  margin: 0%;
  padding: 0%;
  box-sizing: border-box;
  font-family: Arial;
}
li{
  list-style: none;
}

