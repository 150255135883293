.customerView{
    background: rgb(202, 202, 202);
    position: relative;
    overflow-x: hidden;
}
.myProfile > .brand{
    color: white;
    text-align: center;
    padding: 0.7rem;
    font-size: 1.5rem;
}
.myProfile{
    background: #1c7ed6;
    padding-bottom: 3rem;
}
.myProfile > .mine{
    color: white;
    display: flex;
    width: 80%;
    margin: auto;
}
.myProfile > .mine >div{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
.myProfile > .mine button{
    height: 80%;
    width: 35%;
}
.mioInfo{
    background: rgb(240,240,240);
    width: 90%;
    padding: 1rem;
    position: absolute;
    justify-content: space-around;
    text-align: center;
    margin-top: 1rem;
    left: 50%;
    transform: translate(-50%,-0%);
    border-radius: 5px;
}
.InnermoiInfo{
    display: flex;
    justify-content: space-around;
}
.moilist{
    margin-top: 2.2rem;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    position:sticky;
    top: 0;
}

.lists{
    overflow-y: hidden;
    padding: 1rem;
    margin-top: 2rem;
}
.lists .list{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: gray solid 1px;
    padding: 1rem 0.5rem;
}

.moilist .search{
    position: sticky;
    top: 0%;
    padding: 0.5rem;
    background-color: aliceblue;
}
.search input{
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border:none;
    outline: none;
}
.searchviewcus{
    width: 90%;
    margin: auto;
    padding: 10px;
    font-size: 1rem;
    margin-top: 5px;
    border-color: #a5d5ff;
    border-radius: 10px;
    outline: none;
    position: fixed;
}
.moilist > .moilistIn{
    width: 90%;
    height: 95vh;
    margin: 10px auto;
    background: white;
    border-radius: 10px;
    overflow-y: auto;
    position: relative;
}
.lists .list .listLeft{
    display: flex;
    align-items: center;
}
.lists .list .listLeft h3{
    margin-right: 30px;
}