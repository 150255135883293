.regContainer{
    width: 100%;
    margin: auto;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 20px;
    height: 100vh;
    text-align: center;
}
.regContainer .topReg{
    padding: 50px;
}
.regContainer .main{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
}
.regContainer .main .regInput{
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
} 

.regContainer .main .regInput *{
    padding: 10px;
}
.regContainer .main .regInput > button{
    width: 130px;
}
.regContainer .main .formReg{
    width: 50%;
}
.regContainer .main .formReg input{
    width: 90%;
    margin: auto;
}
.regContainer .main .formReg .regInput{
    width: 100%;
}
.regContainer .main .formReg .regInput > div{
    width: 100%;
    align-content: center;

}
.regContainer .main .topRegImage{
    width: 100%;
    padding: 50px;
}

  
  
  .regContainer .main .formReg{
      width: 100%;
  }
  .regContainer .main .formReg input{
      width: 100%;
      margin: auto;
  }
  .regContainer .main .formReg .regInput > div{
      width: 100%;
      padding: 10px 30px 10px 30px ;
  
  }

@media only screen and (min-width: 700px) {
    
    .regContainer {
        width: 70%;
    }
    .main{
        flex-direction: row;
    }
    .regContainer .main .topRegImage{
        width: 50%;
        padding: 50px;
        
    }
  }
