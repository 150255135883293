.mainCon {
    background-color: rgb(240,240,240);

}
.mainCon .navbar{
    background-color: #1c7ed6;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0% 10px;
}
.mainCon .navbar .left{
    display: flex;
    align-items: center;
}
.mainCon .navbar .left ul{
    display: flex;
    align-items: center;
    margin-left: 10px;
}
.mainCon .navbar .left ul li a{
    padding: 15px;
    display: block;
}
.mainCon .navbar .left ul li a:hover{
    background-color: white;
    color: #1c7ed6;
}
.mainCon .navbar .left ul li a{
    text-decoration: none;
    font-weight: 600;
    color: white;
}
.mainCon .navbar .left ul li:hover a{
    color: #1c7ed6;
}
.mainCon .navbar .right .logoutbtn{
    margin-top: 0;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background-color: white;
    font-weight: 700;
    color: black;
}