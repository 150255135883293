.edit{
    width: 100%;
    height: 100%;
    background-color: rgb(232, 232, 232);
}
.editCon{
    display: flex;
    margin: auto;
    width: 90%;
}
.editCon > .left{
    flex: 0.7;
    border-radius: 10px;
}
.editCon > .left .top{
    
    background-color: white;
    margin-top: 20px;
    border-radius: 10px;
    padding: 15px;
}
.editCon > .left .bottom{
    height: 30vh;
    margin-top: 20px;
    border-radius: 10px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
    line-height: 2rem;
}
.editCon > .left  .inputIn{
    display: flex;
    margin: auto;
    margin-top: 10px;
}
.editCon > .left  .inputIn .input_left{
    flex: 0.5;
    padding: 10px;
}
.editCon > .left  .inputIn .input_left .cus{
    margin-bottom: 10px;
    line-height: 29px;
}
.editCon > .left  .inputIn .input_right{
    flex: 0.5;
    padding: 10px;
}
.editCon > .left  .inputIn .input_right > *{
    margin-bottom: 0.9rem;
}
.editCon > .left  .inputIn .input_right  input{
    padding: 10px;
    margin-left: 10px;
    border-radius: 10px;
    outline: none;
    border: gray 1px solid;
}
.editCon > .left  .inputIn .input_right div{
    display: flex;
    align-items: center;
}
.editCon > .left  .inputIn .input_right div textarea{
    padding: 10px;
    border-radius: 5px;
    border: gray 1px solid;
    outline: none;
    margin: 10px 0;
}

.editCon > .left  .inputIn .input_left .detailp div{
    display: flex;
    flex-direction: column;
}
.editCon > .left  .inputIn .input_left div input{
    padding: 10px;
    border-radius: 5px;
    border: gray 1px solid;
    outline: none;
    margin: 10px 0;
}
.editCon > .left  .inputIn .input_left .demoAmount{
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: none;
}
.editCon > .left  .inputIn .input_left .amount{
    display: flex;
}
.editCon > .left  .inputIn .input_left .setzero{
    color: black;
    background-color: rgb(189, 189, 189);
    padding: 5px;
    margin: 10px;
    border: none;
    border-radius: 5px;
}
.editCon > .left  .inputIn .input_left .demonAmount input{
    width: 100px;
    display: inline;
}
.editCon .left .top .submitbtn{
    margin-top: 5px;
    padding: 10px;
    font-size: 15px;
    font-weight: 600;
    background-color: #099268;
    border-radius: 5px;
    border: none;
    color: white;
    width: 98%;
    margin: auto;
    display: block;
} 
.editCon .left .top .submitbtn:hover{
    background-color: #087f5b;
}
.editCon .left .top .arealist{
    overflow-y: auto;
    width:100%;
    display: flex;
    height: 150px;
    flex-direction: column;
    border: 2px solid gray;
    padding: 8px;
    border-radius: 6px;
}
.editCon .left .top .arealist > button{
    font-size: medium;
    width: 100%;
    padding: 3px;
    border: none;
    background-color: white;
}.editCon .left .top .arealist > button:hover{
    background-color: rgb(182, 182, 182);
}
.editCon > .right{
    flex: 0.3;
    background-color: white;
    height: 90vh;
    margin: 20px;
    padding: 15px;
    border-radius: 10px;
}
.editCon > .right >h3{
    margin-bottom: 10px;
}
.editCon >.right > .rightcon{
    height: 76vh;
    overflow-y: auto;
}
.editCon > .right .detail{
    background-color: rgb(242, 242, 242);
    padding: 8px;
    line-height: 21px;
    border-radius: 5px;
    margin: 0.5rem;
}
.editCon > .right .buttons{
    display: flex;
    align-self: center;
    justify-content: space-between;
}
.editCon > .right .buttons *{
    width: 100%;
    text-align: center;
    padding: 8px;
    margin: 1px;
    border: none;
    border-radius: 5px;
}
.editCon > .right .buttons a{
    background-color: #1C7ED6;
    color: aliceblue;
    font-weight: 600;
    text-decoration: none;
}
.editCon > .right .buttons a{
    background-color: #1C7ED6;
    color: aliceblue;
    font-weight: 600;
    text-decoration: none;
}
.editCon > .right .buttons .delbtn{
    background: rgb(214, 0, 0);
    color: aliceblue;
    font-weight: 600;
    text-decoration: none;
}
.editCon > .right .buttons .editbtn{
    background: #099268;
    color: aliceblue;
    font-weight: 600;
    text-decoration: none;
}
.generatepdf{
    padding: 10px;
    font-weight: 600;
}
.searchcus{
    padding:0.5rem;
    font-size: 20px;
    width: 100%;
    outline: none;
    margin-bottom: 10px;
    border-radius: 10px;
    border: 1px gray solid;
}
.demoCon{
    display: flex;
    justify-content: space-between;
}
.limitedDiv{
    width: 100%;
    
}
.limitedDiv > button{
    padding: 15px;
    border: none;
    border-radius: 20px;
    background-color: #1C7ED6;
    outline: none;
    font-weight: 600;
    color:white;
    width:100%
}
.numberIn{
    font-size: 25px;
}
    @media only screen and (max-width: 700px){
        .editCon{
        display: block;
    }
    .editCon   .inputIn{
        display: flex;
        flex-direction: column;
    }
    .demonAmount{
        display: none;
    }
    .list2{
        visibility: hidden;
    position: absolute;
    top: -9999px;;
    }
    .areaInput{
        width:65vw;
        height: 60px;
    }
}