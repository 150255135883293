@media print{
  @page {
      margin: 20mm;
  }
  body{
    background-color: white;
    width: 100%;
    height: 5in;
  }
}
.print_container{
  height: 5in;
}
.print_container .print_content *{
  margin: 0.01rem;
}
  .print_container .print_content{
    border: 3px black;
    width: 100%;
    border-style: dotted;
    padding: 0.7rem;
  }
  .print_container .heade{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    border-bottom: 1px solid black;
    margin-bottom: 5px;
    line-height: 1.2rem;
  }
  .print_container .heade .brandname{
    text-align: center;
  }
  .print_container .heade .brandname > h2{
    font-size: 18px;
  }
  .print_container .heade .brandname > h3{
    font-size: 10px;
  }
  .print_content > .cutDetail > *{
    padding-bottom: 0.1px;
  }
  .print_content > .cutDetail {
    max-width: 100%;
    text-align: center;
    border-bottom: 1px solid black;
  }
  .print_content > .cutDetail h4{
    font-size: 1rem;
    line-height: 1.2rem;
  }
  .print_content > .cutDetail h5{
    font-size: 0.6rem;
    line-height: 1rem;
  }

  .print_content > .moist{
    border-bottom: 1px solid black;
  }
  .print_content > .moist div{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    line-height: 1rem;
  }
  .print_content > .moist div label{
    flex: 0.2;
    font-size: 0.7rem;
  }
  .print_content > .moist div h3{
    font-size: 0.9rem;
    flex: 0.7;
    word-wrap: normal;  
  }
  .print_content > .moist div > div{
    flex: 0.8;
    width: 30px;
    font-size: 0.9rem;
  }
  .print_content > .moist div h4{
    word-wrap: break-word;
    font-size: 0.7rem;
  }
  .print_content > .amountDetail{
    width: 100%;
    margin: auto;
    border-bottom: 1px solid black;
  }
  .print_content > .amountDetail .amountword{
    background-color: black;
    padding: 0.8rem 0.2rem;
    font-size: 1.5rem;
    color: white;
    margin-top: 10px;
  }
  .print_content table {
    width: 100%;
    border-collapse: collapse;
  }
  .print_content .to_amount{
    text-align: center;
    line-height: 2rem;
  }
  .print_content .to_amount tr,th,td{
    border: black solid 1px;
    border-collapse: collapse;
  } 
  .print_content .to_amount h1{
    font-size: 2rem;
  }
  .print_content .to_amount h5{
    font-size: 0.8rem;
  }
  .print_content .to_amount img{
    margin: 0%;
    padding: 0%;
  }
  .print_content .footer {
    line-height: 1.3rem;
    margin-bottom: 10px;
  }
  .print_content .footer h3{
    font-size: 1rem;
    margin-top: 4px;
    text-align: center;
  }
  .print_content .footer h6{
    font-size: 0.9rem;
  }

  .print_content > .operatorname{
    text-align: center;
  }
.operatorname{
    padding: 0.1rem 0;
  }